<div class="alert alert-success alert-section" role="alert">
    <div class="alert-image">
        <img mat-card-image src="/assets/images/success-tick.svg" alt="green tick Icon">

    </div>
    <p class="alert-message"> 
        Please be informed that the bank reviewed and approved the documents and the payment is under processing, if you require any further information please contact the bank
    </p>
</div>
<!-- <div class="alert bg-light-green alert-section" role="alert">
    <p class="alert-message"> 
        Please Review the Following Documents to transfer the Ownership of the goods and confirm the financial transaction/s. All Forms will be
        sent to your bank to check them and processed with the payment. You will get notified when the bank finish.
    </p>
    <div class="alert-button">
        <button mat-button class="card-btn btn-success">
            Approve all Documents
        </button>
    </div>
</div> -->