import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { Product } from 'src/app/shared/models/product.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private baseUrl = environment.baseUrl;
  private headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).authToken}`, // Include any other headers you need
    });
  }

  getRequestsApi() {
    return this.httpClient.get(this.baseUrl + "RFQ", { headers: this.headers });
  }
  getBankName(bank){
    return this.httpClient.get(this.baseUrl + `Bank/${bank}`, { headers: this.headers });
  }
  getUserName(ad) {
    return this.httpClient.get(this.baseUrl + `User/${ad}`, { headers: this.headers });
  }
  getSingleRequestsApi(rfQId) {
    return this.httpClient.get(this.baseUrl + `RFQ/${rfQId}`, { headers: this.headers });
  }
  updateStatusRequest(rfQId, body){
    return this.httpClient.put(this.baseUrl + `RFQ/${rfQId}`,body, { headers: this.headers });

  }
  getContractFormApi(){
    return this.httpClient.get(this.baseUrl + `StartDeal`, { headers: this.headers });
  }
  getDealsFormApi(){
    return this.httpClient.get(this.baseUrl + `Deal`, { headers: this.headers });
  }
  geSingleDealFormApi(id){
    return this.httpClient.get(this.baseUrl + `Deal/${id}`, { headers: this.headers });
  }
}