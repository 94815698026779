import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userlayout',
  templateUrl: './userlayout.component.html',
  styleUrls: ['./userlayout.component.scss']
})
export class UserlayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
