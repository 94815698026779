import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static noAlphabetChars(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (/[a-zA-Z]/.test(value) || /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        return { noAlphabetChars: true };
      }

      return null;
    };
  }
    static noSpecialCharactersOrAlphabets(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
  
        if (/[^\w\s]/.test(value) || /[a-zA-Z]/.test(value)) {
          return { noSpecialCharactersOrAlphabets: true };
        }
  
        return null;
      };
    }
}