import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private baseUrl = environment.baseUrl;
  private headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).authToken}`, // Include any other headers you need
    });
  }

  getContractFormApi() {
    return this.httpClient.get(this.baseUrl + `StartDeal`, { headers: this.headers });
  }
  getDealById(id){
    return this.httpClient.get(this.baseUrl + `Deal/${id}`, { headers: this.headers });
  }
  getBankName(bank){
    return this.httpClient.get(this.baseUrl + `Bank/${bank}`, { headers: this.headers });
  }
  updateDealById(id, body){
    return this.httpClient.put(this.baseUrl + `Deal/${id}`,body, { headers: this.headers });
  }
  updateRFQ(id,body){
    return this.httpClient.put(this.baseUrl + `Deal/${id}`,body, { headers: this.headers });
  }
  postToShipmentStarted(body){
    return this.httpClient.post(this.baseUrl + `StartShipment`,body, { headers: this.headers });
  }
  getUser(id){
    return this.httpClient.get(this.baseUrl + `User/${id}`, { headers: this.headers });
  }
  getShipper(id){
    return this.httpClient.get(this.baseUrl + `ShippingLine/${id}`, { headers: this.headers });
  }
  dealFinished(body){
    return this.httpClient.post(this.baseUrl + `FinalizeDeal`,body, { headers: this.headers });
  }
}