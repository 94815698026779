import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  private baseUrl = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
  ) {}

  getBanksApi() {
    return this.httpClient.get(this.baseUrl + "Bank");
  }
  getSingleBankApi(bankId) {
    return this.httpClient.get(this.baseUrl + `Bank/${bankId}`);
  }
}