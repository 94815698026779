<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<form [formGroup]="bookingForm" (ngSubmit)="onSubmit()" >
  <div class="row d-flex justify-content-end">
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="label">Shipping line</label>
            <input
              type="text"
              class="form-control"
              placeholder="Shipping line"
              value="{{shipperName}}"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="label">Booking number</label>
            <input
              type="text"
              class="form-control"
              value="yz903689"
              placeholder="Booking number" 
              disabled
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Shipper</label>
          <input
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              value="Shipper"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Consignee</label>
          <input
              type="text"
              class="form-control"
              placeholder="Consignee"
              value="{{buyerName}}"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Notify Party</label>
          <input
              type="text"
              class="form-control"
              placeholder="Notify Party"
              value="{{buyerName}}"
              disabled

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="" class="label ">Description of goods</label>
            <textarea
            type="text"
            class="form-control"
            placeholder="Description"
            rows="1"  
            [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"

            formControlName="notes"
          ></textarea>
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Vessel</label>
          <input
              type="text"
              class="form-control"
              placeholder="Vessel"
              name="extraFeilds.vessel"
              [(ngModel)]="extraFeilds.vessel"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Voyage</label>
          <input
              type="text"
              class="form-control"
              placeholder="40E"
              name="extraFeilds.voyage"
              [(ngModel)]="extraFeilds.voyage"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label class="label">Cut-off date</label>
          <input
            disabled
            type="text"
            class="form-control "
            placeholder="Select Date"
            value="{{ getCutOffDate(request?.date)}}"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of Loading</label>
          <select class="form-control" disabled formControlName="OportOfLoading">
              <option selected disabled  >{{request?.shipeFrom || 'Egypt' }}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Estimated time of departure</label>
          <input
              class="form-control"
              placeholder="Estimated time of departure"
              formControlName="estimatedDeparture"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"
              value="getReversedFormatDate()"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of discharge</label>
          <select class="form-control" disabled formControlName="portOfDischarge" >
            <option selected disabled>{{request?.shipeTo || 'Egypt' }}</option>
            <option>
              <!-- dsadasdas -->
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Estimated time of arrival</label>
          <input
              type="text"
              class="form-control"
              placeholder="Estimated time of arrival"
              formControlName="estimatedArrival"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"
              />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Container type</label>
          <select class="form-control" formControlName="containerType">
            <option selected value="">{{deal?.containerType.split(' ')[0]  || 'Container type'}}</option>
            <option>
              <!-- dsadasdasx -->
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Container number</label>
          <input
              type="number"
              class="form-control"
              placeholder="Container number"
              value="{{request?.rFQId}}"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">HS CODE</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              disabled
              value="{{request?.product.split('#')[1]}}"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total gross weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="Total gross weight"
              [(ngModel)]="extraFeilds.totalGrossWeight"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Gross weight/container</label>
          <input
              type="number"
              class="form-control"
              placeholder="Gross weight/container"
              [(ngModel)]="extraFeilds.grossWeightContainer"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group arrow-down">
              <label class="label">Unit</label>
              <select [disabled]="userData.accountType == 'buyer'" class="form-control" formControlName="unit">
              <option selected value="" >Mt</option>
              <option *ngFor="let unit of UNITS">
              {{unit}}
              </option>
              </select> 
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Prepaid</label>
          <select disabled [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"  class="form-control" formControlName="unit">
            <option selected value="" >--{{formsJSVAR.prePaid}}</option>
            <option *ngFor="let value of ['yes', 'no']">
            {{value}}
            </option>
            </select> 
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Container Quantity</label>
          <input
              type="number"
              class="form-control"
              placeholder="Container Quantity"
              formControlName="containerQuantity"
              [disabled]="userData?.accountType == 'buyer' || userData?.accountType == 'shipper' || deal?.containerType.split(' ').includes('bookingGenerated')"

          />
          </div>
      </div>

    <div *ngIf="!deal?.containerType.split(' ').includes('bookingAccepted')" class="col-md-7 request-quotation-form-btn text-end">
      <button (click)="onReject()"  *ngIf="(userData?.accountType == 'shipper' && deal?.status !== 'booked') " mat-button class="btn btn-outline-secondary card-btn-secondary" type="button">
        <span *ngIf="cancelLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Cancel Booking
    </button>
      <button *ngIf="(userData?.accountType == 'shipper' && deal?.status !== 'booked') " (click)="onAccept()" type="button" mat-button class="card-btn">
        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
         Approve Booking 
      </button>
      <button *ngIf="(userData?.accountType == 'seller' && !deal?.containerType.split(' ').includes('bookingGenerated'))"  mat-button class="card-btn"  type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
       Generate Booking 
     </button>
    </div>
  </div>
</form>