<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<form [formGroup]="packingForm" (ngSubmit)="onSubmit()" >
  <div class="row d-flex justify-content-end">
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
            <label class="label">PL Number</label>
            <input
              disabled
              type="number"
              class="form-control"
              placeholder="000000000"
              value="{{rFQId}}"
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">PL date</label>
          <input
              class="form-control "
              placeholder="Select Date"
              disabled
              value="{{ getReversedFormatDate()}}"
              />
            </div>
            <!-- formControlName="estimatedArrival" -->
            <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Vessel</label>
          <input
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              name="extraFeilds.vessel"
              [(ngModel)]="extraFeilds.vessel"
              [ngModelOptions]="{standalone: true}"  
              disabled
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
          <label class="label">Voyage</label>
          <input
              disabled
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              name="extraFeilds.voyage"
              [(ngModel)]="extraFeilds.voyage"
              [ngModelOptions]="{standalone: true}"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="" class="label ">Description of goods</label>
            <textarea
            type="text"
            class="form-control"
            placeholder="Description"
            rows="1"                                          
            value="{{product?.notes.split('|')[0]}}"
            disabled
            ></textarea>
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group double-arrow">
          <label class="label">Container Quantity</label>
          <input
            type="number"
            class="form-control"
            placeholder="Select Quantity"
            value="{{deal?.containerQuantity}}"
            disabled
            />
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label ">Origin Country</label>
          <input
              type="text"
              class="form-control"
              placeholder="10"
              value="{{request?.shipeFrom}}"
              disabled
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of Loading</label>
          <select disabled class="form-control ">
            <option selected>{{deal?.OportOfLoading || 'Egypt'}}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of Discharge</label>
          <select disabled class="form-control ">
            <option selected>{{request?.shipeTo || 'Egypt'}} </option>
            <option>
            </option>
          </select>
        </div>
      </div>
    <div class="col-12 col-lg-6">
      <div class="form-group double-arrow">
        <label class="label">Bill of Lading Number</label>
        <input
          type="number"
          class="form-control"
          placeholder="10"
          value="{{deal?.Key}}"
          [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
        />
      </div>
    </div>
      <div class="col-12 col-lg-6">
          <div class="form-group ">
          <label class="label">Total net Weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalNetWeight"
              [(ngModel)]="extraFeilds.totalNetWeight"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total gross weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalGrossWeight"
              [(ngModel)]="extraFeilds.totalGrossWeight"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Exporter</label>
        <input
            type="text"
            class="form-control"
            placeholder="WorldImporters"
            value="{{sellerName}}"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
        />
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Importer</label>
        <input
            type="text"
            class="form-control"
            placeholder="WorldImporters"
            value="{{buyerName}}"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "

        />
        </div>
    </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Container Number</label>
        <input
            type="number"
            class="form-control"
            placeholder="100"
            value="{{formsJSVAR.bookingNumber}}"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
        />
        </div>
    </div>


    <div class="col-12 col-lg-6">
        <div class="form-group  arrow-down">
          <label class="label ">Container type</label>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated')"  -->
          <select class="form-control" formControlName="containerType">
            <option [disabled]="deal?.containerType.split(' ')[0].length !== 0" selected >{{deal?.containerType.split(' ')[0] || '--Select Container Type'}}</option>
            <option *ngFor="let type of containerTypes">
              {{type}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Net weight/container</label>
        <input
            type="number"
            class="form-control"
            placeholder="100"
            [(ngModel)]="extraFeilds.netWeightContainer"
            [ngModelOptions]="{standalone: true}"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
        />
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Gross weight/container</label>
        <input
            type="number"
            class="form-control"
            placeholder="100"
            name="extraFeilds.grossWeightContainer"
            [(ngModel)]="extraFeilds.grossWeightContainer"
            [ngModelOptions]="{standalone: true}"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('packingGenerated') "
        />
        </div>
    </div>
    <div *ngIf="(userData.accountType=='seller' && !deal?.containerType.split(' ').includes('packingGenerated')) || (userData.accountType=='buyer' && !deal?.containerType.split(' ').includes('packingAccepted')) " class="col-md-7 request-quotation-form-btn text-end">
      <button (click)="onReject()" type="button" *ngIf="userData.accountType == 'buyer' " mat-button class="btn btn-outline-secondary card-btn-secondary" >
        <span *ngIf="cancelLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Cancel Packing
    </button> 
      <button *ngIf="userData.accountType == 'buyer' " type="button" (click)="onAccept()" mat-button class="card-btn">
        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
         Approve Packing 
      </button>
      <button *ngIf="userData.accountType == 'seller'"  mat-button class="card-btn"  type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
       Generate Packing 
     </button>
    </div>
  </div>
</form>