import { Component, OnInit, NgModule, Input, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as EventEmitter from 'events';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/views/add-product/services/product-service';
import { FormService } from 'src/app/views/all-forms/services/formService';
import { RequestService } from 'src/app/views/my-request/services/requests-service';
import { ContractService } from '../contract-form/services/contract-service';
import { vessel, voyage, totalGrossWeight, containerNumber, prePaid, GrossWeightContainer } from "../../forms-js-variables/forms-varibles"
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.html',
  styleUrls: ['./booking-form.scss']
})
export class BookingFormComponent implements OnInit {
  formsJSVAR = {
    vessel,
    voyage,
    totalGrossWeight,
    containerNumber,
    prePaid,
    GrossWeightContainer
  }
  extraFeilds = {
    vessel:"",
    voyage:"",
    grossWeight:0,
    netweight:0,
    totalNetWeight:0,
    totalGrossWeight:0,
    grossWeightContainer:0
  }
  sellerName
  buyerName
  shipperName
  shippingLine:any = "org.acme.supply_chain_network.ShippingLine#4972"
  loading: Boolean = false
  cancelLoading:Boolean = false
  acceptLoading:Boolean = false
  @Input() rFQId;
  deal: any
  request: any
  @Output() formData: EventEmitter = new EventEmitter();
  formLoading:Boolean = false
  bookingForm: FormGroup
  userData = JSON.parse(localStorage.getItem('userData'));
  checkHandlingNav = ''
  UNITS = ['g', 'Kg', 'MT']
  constructor(private router: Router, private fb: FormBuilder, private contractService: ContractService, private requestService: RequestService, private httpProdcutService: ProductService, private toastr: ToastrService, private formService: FormService) {
    this.bookingForm = this.fb.group({
      shippingLine: [{ disabled: true, value: '' }, Validators.required],
      bookingNumber: ["", Validators.required],
      containerNumber: ["", Validators.required],
      prePaid: ["", Validators.required],
      shipper: ["", Validators.required],
      vessel: ["", Validators.required],
      voyage: ["", Validators.required],
      OportOfLoading: ['', Validators.required],
      portOfDischarge: ['', Validators.required],
      containerType: ['', Validators.required],
      containerQuantity: ['', Validators.required],
      grossWeightContainer: ["", Validators.required],
      notifyParty: [{ value: '', disabled: true }, Validators.required],
      cosignee: [{ value: '', disabled: true }, Validators.required],
      notes: ['', Validators.required],
      HSCode: [{ value: '', disabled: true }, Validators.required],
      dayCut: [0, Validators.required],
      bookingDate: [Date, Validators.required],
      estimatedArrival: [{ value: '', disabled: true }, Validators.required],
      estimatedDeparture: [{ value: '', disabled: true }, Validators.required],
      unit: [{ value: '', disabled: true }],
    });
  }
  onSubmit() {
    const { estimatedDeparture, estimatedArrival } = this.bookingForm.value
    const { Key, containerType, ...rest } = this.deal
    const body = {
      ...rest,
      containerType: containerType + ' bookingGenerated',
      timeToArraive: estimatedArrival,
      timeToDepart: estimatedDeparture,
      portOfDischarge: this.deal.portOfDischarge.split("#")[0] + "#",
      ...this.extraFeilds
    }
    this.loading = true
    console.log(body, "this is the body")
    this.formService.updateDealById(this.rFQId, body).subscribe((data: any) => {
      if (data) {
        this.loading = false
      }
      this.toastr.success("Booking Confirmation Sent")
      this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
    })
  }
  getReversedFormatDate(){
    const currentDate = new Date()
    let dd = String(currentDate. getDate()). padStart(2, '0');
    let mm = String(currentDate. getMonth() + 1). padStart(2, '0');
    var yyyy = currentDate. getFullYear();
    return  dd + '-' + mm + '-' + yyyy;
    }
  onReject() {
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any) => {
      this.cancelRFQ(this.rFQId, data)
    })
  }
  cancelRFQ(rfQId, request) {
    const { rFQId, myProduct, ...rest } = request
    this.cancelLoading = true
    this.requestService.updateStatusRequest(rfQId, { ...rest, status: 'reject' }).subscribe((data: any) => {
      if (data) {
        this.cancelLoading = false
        this.router.navigate(['/dash', 'requests'])
        this.toastr.success("Rejected RFQ Successfully")
      }
    })
  }
  getDataWithoutHash(seller) {
    return seller.split('#')[1];
  } 
   
  setExporter(seller){
    this.contractService.getUser(this.getDataWithoutHash(seller)).subscribe((data: any)=>{
    this.sellerName = data.name
    })
  }
  setShippingLine(shipper = this.shippingLine){
    this.contractService.getShipper(this.getDataWithoutHash(shipper)).subscribe((data: any)=>{
    this.shipperName = data.name
    })
  }
  setImporter(buyer) {
    this.contractService.getUser(this.getDataWithoutHash(buyer)).subscribe((data: any)=>{
    this.buyerName = data.name
    })
  }
  getCutOffDate(date){
    let nDate = new Date(date)
   let newDate = new Date(nDate.getTime()+30*24*60*60*1000);

    let ldate = newDate.getDate()
    let lmonth = newDate.getMonth() + 1
    let lyear = newDate.getFullYear()
    // console.log(ldate + '/' + lmonth + '/' + lyear, "After")
    return ldate + '-' + lmonth + '-' + lyear
  }
  onAccept() {
    let id = this.deal.Key
    const { Key, ...rest } = this.deal
    this.acceptLoading = true
    this.contractService.updateDealById(id, { ...rest, status: 'booked', containerType:this.deal.containerType + ' bookingAccepted', rFQ: `org.acme.supply_chain_network.RFQ#${this.rFQId}` }).subscribe((data: any) => {
      if (data) {
        this.acceptLoading = false
      }
      this.toastr.success("Successfully Accepted the form")
      this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
    })
  }
  ngOnInit(): void {
    if(this.userData.accountType == 'buyer' || this.userData.accountType == 'shipper'){
      this.bookingForm.get('unit').disable()
    }
    this.formLoading = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{ 
      this.request = data
      console.log(data, "this is the dataaa")
      this.setExporter(data.seller)
      this.setImporter(data.buyer)
      this.setShippingLine()
      this.bookingForm.get('shippingLine').setValue(this.shipperName)
      // if(this.userData.accountType == 'buyer' || this.userData.accountType == 'shipper'){
        this.bookingForm.get('estimatedDeparture').setValue(formatDate(data.date,'dd-MM-yyyy','en'))
        this.bookingForm.get('estimatedArrival').setValue(this.getCutOffDate(data.date))
      // }
      this.httpProdcutService.getSingleProductApi(this.request.product.split('#')[1]).subscribe((data: any) => {
        // console.log(data, "this is the produxts")
        this.bookingForm.get('notes').setValue(data.notes.split('|')[0])
      })
      this.formLoading = false
    })

    this.bookingForm.get('estimatedDeparture').setValue(this.getReversedFormatDate())
    // this.bookingForm.get('estimatedArrival').setValue(this.getReversedFormatDate())
    this.contractService.getDealById(this.rFQId).subscribe((data: any) => {
      this.deal = data
      // this.extraFeilds = JSON.parse(data.portOfDischarge.split('#')[1])

       const {
        vessel,
        voyage,
        grossWeight,
        netweight,
        totalNetWeight,
        totalGrossWeight,
        grossWeightContainer
      } = data
      this.extraFeilds =  {
      vessel,
      voyage,
      grossWeight,
      netweight,
      totalNetWeight,
      totalGrossWeight,
      grossWeightContainer
    }
      if(this.deal.containerType.split(' ').includes('bookingGenerated')){  
        this.bookingForm.get('estimatedDeparture').disable()
        this.bookingForm.get('estimatedArrival').disable()
      }
      let currDeal = this.deal.containerType.split(" ")
      if(this.userData.accountType == 'seller'){
        if(currDeal.includes('bookingGenerated')){
          this.bookingForm.get('containerQuantity').setValue(this.deal.containerQuantity)
          }
      }
      if(this.userData.accountType == 'buyer'){
        if(currDeal.includes('bookingGenerated')){
        }
   }
      this.formLoading = false
      this.bookingForm.get('containerQuantity').setValue(data.containerQuantity)
      this.bookingForm.get('containerQuantity').setValue(data.containerQuantity)
      this.bookingForm.get('dayCut').setValue(data?.dayCut)
      this.bookingForm.get('notifyParty').setValue(this.buyerName)
      this.bookingForm.get('cosignee').setValue(this.sellerName)
      this.bookingForm.get('bookingNumber').setValue(this.rFQId)
      this.bookingForm.get('shipper').setValue(this.buyerName)
      this.bookingForm.get('HSCode').setValue(data?.product.split('#')[1])
      this.bookingForm.get('containerQuantity').disable()
      this.bookingForm.get('containerType').disable()
      this.bookingForm.get('notes').disable()
      this.bookingForm.get('dayCut').disable()
      this.bookingForm.get('bookingNumber').disable()

    })
  }
}
