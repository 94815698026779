import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  baseUrl = environment.baseUrl;
  private headers: HttpHeaders;

  constructor(private _httpClient: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).authToken}`, // Include any other headers you need
    });
  }

  addProduct(product) {
    return this._httpClient.post(`${this.baseUrl}Product`, product, {
      observe: 'response',
      headers: this.headers
    });
  }
 getProductsApi() {
  console.log(this,this.headers, "These are the headers")
    return this._httpClient.get(this.baseUrl +"Product", { headers: this.headers });
  }
  deleteProductApi(id) {
    return this._httpClient.delete(this.baseUrl +`Product/${id}`, { headers: this.headers });
  }
  updateProductApi(id,updatedForm) {
    return this._httpClient.put(this.baseUrl +`Product/${id}`,updatedForm, { headers: this.headers });
  }
  getSingleProductApi(id){
    return this._httpClient.get(this.baseUrl +`Product/${id}`, { headers: this.headers });
  }
}


