<div class="proucts-wrap request-negotiations-content">
    <div class="container-fluid custom-container mt-5">
        <div class="row d-flex justify-content-center">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm 12 col-xs-12">
                <app-side-nav></app-side-nav>
            </div>
            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm 12 col-xs-12">
                <div class="bg-white h-100 align-items-center {{!users.length && 'justify-content-center'}} d-flex">
                    <div *ngIf="loading" class="text-center mt-4">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    <p *ngIf="!loading && users.length == 0" class="fs-5 text-center mt-2"> There is no request yet</p>
                    <div *ngFor="let user of users" class="request-negotiations d-flex align-items-center p-3">
                        <div class="request-header">
                            <h4 class="h4 mb-2"> <span class="text-light-black">Username:</span>  {{user.name}}</h4>
                            <h4 class="h4"> <span class="text-light-black">Registration Date:</span> 27/02/22, 4:00pm</h4>
                        </div>
                        <div class="buttons">
                            <button (click)="rejected(user.participantKey, user)" class="btn btn-outline-danger custom-btn">
                                Decline
                            </button>
                            <button (click)="approved(user.participantKey, user)" class="btn btn-success ms-3 custom-btn">
                                Approve
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

