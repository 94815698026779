import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserlayoutComponent } from './core/userlayout/userlayout.component';



const routes: Routes = [
  {
    path: 'dash',
    component: UserlayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import("./views/user-management/user-management.module").then((m) => m.UserManagementModule)
      },
      {
        path: 'request-quotation/:id/:imgId',
        loadChildren: () => import("./views/request-quotation/request-quotation.module").then((m) => m.RequestQuotationModule)
      },
      {
        path: 'quotation-successful',
        loadChildren: () => import("./views/quotation-successful/quotation-successful.module").then((m) => m.QuotationSucessfulModule)
      },
      {
        path: 'my-product',
        loadChildren: () => import("./views/my-product/my-product.module").then((m) => m.MyProductModule)
      },
      {
        path: 'add-product',
        loadChildren: () => import("./views/add-product/add-products.module").then((m) => m.AddProductModule)
      },
      {
        path: 'my-request',
        loadChildren: () => import("./views/my-request/my-request.module").then((m) => m.MyRequestModule),
        data:{user:JSON.parse(localStorage.getItem("userData"))}
      },
      {
        path: 'requests',
        loadChildren: () => import("./views/requests-component/request..module").then((m) => m.RequestModule),
        data:{user:JSON.parse(localStorage.getItem("userData"))}
      },
      {
        path: 'documents-form/:formName/:rfQId',
        loadChildren: () => import("./views/all-forms/all-forms.module").then((m) => m.AllFormsModule)
      },
      {
        path: 'admin-dashboard',
        loadChildren: () => import("./views/all-deals/all-deals.module").then((m) => m.AllDealsModule)
      },
      {
        path: 'registration-requests',
        loadChildren: () => import("./views/registration-requests/registration.module").then((m) => m.RegistrationModule)
      }
    ],

  },
  {
    path: 'auth',
    loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
