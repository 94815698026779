<nav class="nav {{checkHandlingNav ==='home' && 'home-navigation'}}">
    <ul class="nav-list">
        <li class="nav-list-item first">
            <a class="link" (click)="navigateToHome()">
                <img [src]="'./assets/images/Impex-Logo.png'">
            </a>
        </li>
        <li *ngIf="userData.accountType !== 'admin'" class="nav-list-item ">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle menu-toggle settings"
                    aria-controls="dropdown-basic">
                    <img class="img-fluid" src="/assets/images/envelop.png">
                    {{userData.accountType == 'seller' ? 'Exporter:' : userData.accountType == 'shipper' ? 'Shipper':
                    userData.accountType == 'admin' ? 'Admin:': userData.accountType == 'government' ? 'Government:':
                    userData.accountType == 'bank' ? 'Bank:':'Importer:' }} {{companyName}}
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li *ngIf="userData.accountType == 'seller'" role="menuitem"><a class="dropdown-item"
                            [routerLink]="['/dash', 'my-product']">My Products</a></li>
                    <li role="menuitem"><a class="dropdown-item" [routerLink]="['/dash','requests']">My Requests</a>
                    </li>
                </ul>
            </div>
        </li>
        <li *ngIf="userData.accountType == 'admin'" class="nav-list-item ">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle menu-toggle settings"
                    aria-controls="dropdown-basic">
                    <img class="img-fluid" src="/assets/images/envelop.png">
                    IMPEX: Admin
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" [routerLink]="['/dash','admin-dashboard']">All
                            Deals</a></li>
                    <li role="menuitem"><a class="dropdown-item"
                            [routerLink]="['/dash','registration-requests']">Registration Requests</a></li>
                </ul>
            </div>
        </li>
        <li *ngIf="userData.accountType == 'seller' || userData.accountType=='buyer'" class="nav-list-item">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button"
                    class="btn dropdown-toggle menu-toggle deliver-btn" aria-controls="dropdown-basic">
                    <img class="img-fluid" src="/assets/images/location.png">
                    Deliver to <span class="text-blue text-bold font-14">{{userData.address.country}}</span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" href="#">{{userData.address.country}}</a></li>
                </ul>
            </div>
        </li>
        <li class="nav-list-item">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle menu-toggle lang-btn"
                    aria-controls="dropdown-basic">
                    English
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" href="#">dfdf</a></li>
                    <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>
                    <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>
                    </li>
                </ul>
            </div>
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle menu-toggle lang-btn"
                    aria-controls="dropdown-basic">
                    Actions
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" href="#" (onclick)="logout()">Logout</a></li>

                </ul>
            </div>
        </li>
    </ul>
</nav>