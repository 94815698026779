import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './services/userService';

@Component({
  selector: 'app-registration-requests',
  templateUrl: './registration-requests.component.html',
  styleUrls: ['./registration-requests.component.scss']
})
export class RegistrationRequestsComponent implements OnInit {
  loading:Boolean = false
  users
  constructor(private userService:UserService, private toastr: ToastrService) { }
  approved(id, body){
  const {participantKey, ...rest} = body
  this.userService.updateStatus(id,{...rest, status:'approved', certNumber:"1231", certType:"444"}).subscribe((response: any)=>{
    if(response){
      this.toastr.success("Successfully approved the status.")
      this.getUsers()
    }
  })
  }
  rejected(id, body){
  const {participantKey, ...rest} = body

    this.userService.updateStatus(id,{...rest, status:'rejected', certNumber:"1231", certType:"444"}).subscribe((response: any)=>{
      if(response){
        this.toastr.success("Successfully approved the status.")
        this.getUsers()
      }
    })
    }
  getUsers(){
    this.users = []
    this.loading = true
     this.userService.getUsers().subscribe((response: any)=>{
       if(response){
         this.loading = false
       }
     response.map((user)=>{
       if(user.status=== 'pending'){
         this.users.push(user)
       }
     })
    })
  }
  ngOnInit(): void {
    this.getUsers()
  }

}
