// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {MatCheckboxModule} from '@angular/material/checkbox';

// Modules
// import { MaterialImportsModule } from './material-imports/material.module';

// Pipes
import { TranslatePipe } from './pipes/translates.pipe';
import { TranslateApiPipe } from './pipes/translateApi.pipe';
import {MyProductFilterPipe} from "./pipes/productFilterPipe";
import { SubmitBtnComponent } from './components/submit-btn/submit-btn.component';
import { ToastrModule } from 'ngx-toastr';
import { SingleProductComponent } from './components/single-product/single-product.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { FilesUploadIconComponent } from './components/files-upload-icon/files-upload-icon.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import {ContractFormComponent} from "./components/contract-form/contract-form-component"
import { BillLadingFormComponent } from './components/bill-lading-form/bill-lading-form-component';
import { BookingFormComponent } from './components/booking-confirmation-form/booking-form-component';
import { CommercialFormComponent } from './components/commercial-invoice-form/commercial-invoice-form-component';
import {PackingListFormComponent} from "./components/packing-list-form/packing-list-form-component"
import {CertificateOriginFormComponent} from "./components/certificate-origin-form/certificate-origin-form-component"
import { ContractModalComponent } from './contract-modal/contract-modal.component';
import { ProceedPaymentButtonComponent } from './components/proceed-payment-button/proceed-payment-button.component';
// Angular material



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatCheckboxModule,
    // MaterialImportsModule,
    HttpClientModule,
    ToastrModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    // directives
    //Pipes
    TranslatePipe,
    TranslateApiPipe,
    MyProductFilterPipe,
    // Components
    SubmitBtnComponent,
    SingleProductComponent,
    SideNavComponent,
    ContractFormComponent,
    BillLadingFormComponent,
    BookingFormComponent,
    CommercialFormComponent,
    PackingListFormComponent,
    CertificateOriginFormComponent,
    ActionButtonComponent,
    FilesUploadIconComponent,
    AlertMessageComponent,
    ContractModalComponent,
    ProceedPaymentButtonComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    // MaterialImportsModule,
    HttpClientModule,
    SubmitBtnComponent,
    SideNavComponent,
    // directives
    //Pipes
    TranslatePipe,
    TranslateApiPipe,
    MyProductFilterPipe,
    // Components
    SingleProductComponent,
    SideNavComponent,
    ContractFormComponent,
    BillLadingFormComponent,
    BookingFormComponent,
    CommercialFormComponent,
    PackingListFormComponent,
    CertificateOriginFormComponent,
    ProceedPaymentButtonComponent,

    MatCheckboxModule,
    ActionButtonComponent,
    FilesUploadIconComponent,
    AlertMessageComponent
  ],
  providers: [
    DatePipe
  ],
})
export class SharedModule { }
