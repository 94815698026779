<div class="product">
    <img *ngIf="product.notes" [src]="splitImg(product.notes)" class="img-fluid">
    <div class="info">
        <h5>{{product.name}}</h5>
        <div class="exporter">
            <img [src]="'./assets/images/market.png'" class="img-fluid">
            <p>Exporter : {{getExporter(product.seller)}}</p>
        </div>
        <div class="notes">
            <p>{{ splitDescription(product.notes)}}</p>
        </div>
        <ul class="details">
            <li>Price per Unit: <strong>{{product.unitPrice | currency : "$"}}</strong></li>
            <li>Unit: <strong>{{product.mOQ}}</strong></li>
            <li>Minimum order quantity : <strong>{{product.mOQ}}</strong></li>
        </ul>
        <div class="action-btn">
            <ng-content select=".singleButton"></ng-content>
            <ng-content select=".group-button"></ng-content>
        </div>
    </div>
</div>