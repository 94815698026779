import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly baseUrl = environment.baseUrl;
  private headers: HttpHeaders;
  constructor(
    private httpClient: HttpClient
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData'))?.authToken}`, // Include any other headers you need
    });
  }

  registerApi(body) {
    return this.httpClient.post(`${this.baseUrl}User/register`, body, {
      observe: 'response',
      headers: this.headers
    })
  }
  loginApi(body) {
    // if (companyName === "shipping@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}ShippingLine/${id}`, {
    //     observe: "response",
    //     headers: this.headers
    //   })
    // }
    // if (companyName === "admin@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Customs/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }
    // if (companyName === "government@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Customs/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }
    // if (companyName === "bank@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Bank/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }

    return this.httpClient.post(`${this.baseUrl}User`,body, {
      observe: "response",
      headers: this.headers
    })
  }

  getSingleUserApi(id) {
    // if (companyName === "shipping@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}ShippingLine/${id}`, {
    //     observe: "response",
    //     headers: this.headers
    //   })
    // }
    // if (companyName === "admin@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Customs/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }
    // if (companyName === "government@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Customs/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }
    // if (companyName === "bank@mail.com") {
    //   return this.httpClient.get(`${this.baseUrl}Bank/${id}`, {
    //     observe: "response",
    //     headers: this.headers

    //   })
    // }

    return this.httpClient.get(`${this.baseUrl}User/${id}`, {
      observe: "response",
      headers: this.headers
    })
  }

  setUserData(body) {
    localStorage.setItem('userData', JSON.stringify(body));
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("userData"));
  }

  getSellerName() {
    this.getUserData().name;
  }
  // getUserData() {
  //   return this.httpClient.get(`${this.baseUrl}User/${}`)
  // }
}
