import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productFilter',
    pure: false
})
export class MyProductFilterPipe implements PipeTransform {
    transform(products$: any[], productName): any {
        if (!products$ || !productName) {
            return products$;
        }
        // filter products$ array, products$ which match and return true will be
        // kept, false will be filtered out
        return products$.filter(item => item.name.toLowerCase().indexOf(productName) !== -1);
    }
}