<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<form [formGroup]="contractForm" (ngSubmit)="onSubmit()">
  <div class="row d-flex justify-content-end">
    <div class="col-12 col-lg-6">
      <div class="form-group disabled">
        <label class="label">Contract number</label>
        <input type="number" class="form-control" placeholder="00000000000" formControlName="Key" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Contract date</label>
        <input class="form-control " placeholder="Select Date" formControlName="contractDate"
          [value]="getReversedFormatDate()" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Exporter</label>
        <input type="text" class="form-control" placeholder="WorldImporters" formControlName="exporter" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label for="" class="label ">Exporter address</label>
        <textarea type="text" class="form-control" placeholder="WorldImporters address" rows="1"
          formControlName="exporterAddress"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group disabled">
        <label class="label">Importer</label>
        <input disabled type="text" class="form-control" placeholder="WorldImporters" formControlName="importer" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label for="" class="label ">Importer Address</label>
        <textarea type="text" class="form-control" placeholder="Importer Address" rows="1"
          formControlName="importerAddress"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label for="" class="label ">Description of goods</label>
        <textarea type="text" class="form-control" placeholder="Description" rows="1"
          formControlName="notes"></textarea>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group arrow-down">
        <label class="label ">Unit</label>
        <select disabled class="form-control">
          <option selected value="">{{request?.units}}</option>
          <option>
            Kg
          </option>
          <option>
            g
          </option>
          <option>
            Mt
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group double-arrow">
        <label class="label">Quantity</label>
        <input type="number" class="form-control" placeholder="Select Quantity" formControlName="quantity" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group double-arrow">
        <label class="label">Net Weight</label>
        <input type="number" class="form-control" placeholder="00" [(ngModel)]="extraFeilds.netweight"
          [disabled]="userData?.accountType === 'buyer'" [ngModelOptions]="{standalone: true}"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group ">
        <label class="label">Gross Weight</label>
        <input type="number" class="form-control" placeholder="00" name="extraFeilds.grossWeight"
          [(ngModel)]="extraFeilds.grossWeight" [disabled]="userData?.accountType === 'buyer'"
          [ngModelOptions]="{standalone: true}" (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Unit price (USD)</label>
        <input type="number" class="form-control" placeholder="00" formControlName="unitPrice" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">TOTAL price</label>
        <input type="number" class="form-control" placeholder="000" formControlName="totalPrice"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group arrow-down">
        <label class="label">INCO Term</label>
        <select disabled class="form-control ">
          <option selected value="">{{request?.shippingTerms[0]}}</option>
          <option>
          </option>
        </select>
      </div>
    </div>
    <!-- <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Contract Number</label>
        <input
            type="number"
            class="form-control"
            placeholder="00"
            value="{{request?.rFQId}}"
            disabled
        />
        </div>
    </div> -->
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Total net weight</label>
        <input type="number" class="form-control" placeholder="00" [(ngModel)]="extraFeilds.totalNetWeight"
          [ngModelOptions]="{standalone: true}" [disabled]="userData?.accountType === 'buyer'"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Total gross weight</label>
        <input type="number" class="form-control" placeholder="00" [(ngModel)]="extraFeilds.totalGrossWeight"
          [ngModelOptions]="{standalone: true}" [disabled]="userData?.accountType === 'buyer'"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">HS CODE</label>
        <input type="number" class="form-control" placeholder="00" value="{{request?.product.HSCode}}" disabled />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Container Quantity</label>
        <input type="number" class="form-control" placeholder="00" formControlName="containerQuantity"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group arrow-down">
        <label class="label">Port of Loading</label>
        <select disabled class="form-control ">
          <option selected>{{request?.shipeFrom}}</option>
          <option>
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group arrow-down">
        <label class="label">Port of discharge</label>
        <select disabled class="form-control ">
          <option selected>{{request?.shipeTo}}</option>
          <option>
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group arrow-down">
        <label class="label">Origin Country</label>
        <select disabled class="form-control ">
          <option selected>{{request?.shipeFrom}}</option>
          <option>
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Latest Shipment Date</label>
        <input type="text" class="form-control " disabled placeholder="Select Date"
          [value]="getThirtyDaysAfterDate(request?.date)" />
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Tolerance %</label>
        <input type="number" class="form-control" placeholder="%" formControlName="dayCut"
          (keypress)="preventE($event)" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Bank Name</label>
        <input type="text" class="form-control" placeholder="Bank Name" value="{{bankName}}" disabled />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Bank Account</label>
        <input type="text" class="form-control" placeholder="Bank Account" value="{{userData?.bankAcccount}}"
          disabled />
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="form-group">
        <label class="label">Payment Terms</label>
        <input type="text" class="form-control" placeholder="Payment Terms"
          value="{{request?.paymentTerms || 'Cash Against Documents'}}" disabled />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="form-group  arrow-down">
        <label class="label ">Container type</label>
        <select class="form-control" formControlName="containerType">
          <option selected>{{StartDeal?.containerType.split(' ')[0] || 'Container type'}}</option>
          <option *ngFor="let type of containerTypes">
            {{type}}
          </option>
        </select>
      </div>
      <mat-checkbox *ngIf="StartDeal?.status !== 'dealApproved'" (change)="handleCheckbox($event)"
        class="example-margin">I accept terms and conditions</mat-checkbox>
    </div>
    <div
      *ngIf="(userData.accountType=='buyer' && !StartDeal?.containerType.split(' ').includes('contractAccepted')) || (userData.accountType=='seller' && !StartDeal?.containerType.split(' ').includes('contractGenerated'))"
      class="col-md-7 request-quotation-form-btn text-end">
      <button (click)="onReject()" *ngIf="userData.accountType == 'buyer' " mat-button
        class="btn btn-outline-secondary card-btn-secondary" type="button">
        <span *ngIf="rejectLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Cancel Contract
      </button>
      <button *ngIf="userData.accountType == 'buyer'" type="button" (click)="onAccept()" mat-button class="card-btn">
        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Approve Contract
      </button>
      <button *ngIf="userData.accountType == 'seller' && StartDeal?.status !== 'dealApproved' " mat-button
        class="card-btn" type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Generate Contract
      </button>
    </div>
  </div>
</form>