import { Component, OnInit ,NgModule, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { ProductService } from 'src/app/views/add-product/services/product-service';
import { FormService } from 'src/app/views/all-forms/services/formService';
import { RequestService } from 'src/app/views/my-request/services/requests-service';
import {vessel,voyage, totalGrossWeight, containerNumber, prePaid, GrossWeightContainer, totalNetWeight} from "../../forms-js-variables/forms-varibles"
import { ContractService } from '../contract-form/services/contract-service';

@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-origin-form.html',
  styleUrls: ['./certificate-origin-form.scss']
})
export class CertificateOriginFormComponent implements OnInit {
  formsJSVAR = {
    prePaid,
    }
extraFeilds = {
  vessel:"",
  voyage:"",
  grossWeight:"",
  netweight:"",
  totalNetWeight:"",
  totalGrossWeight:"",
  grossWeightContainer:"",
  netWeightContainer:""
}
  shipperName
  sellerName
  buyerName
  shippingLine:any= "org.acme.supply_chain_network.ShippingLine#4972"
  request:any
  @Input() rFQId;
  loading: boolean = false
  formLoading:Boolean = false
  deal:any
  cancelLoading: boolean = false
  acceptLoading:boolean = false
  product:any
  certificateForm: FormGroup
  userData = JSON.parse(localStorage.getItem('userData'));
  checkHandlingNav = ''
  UNITS=['g', 'Kg', 'MT']
  constructor(private router:Router, private fb:FormBuilder,private contractService: ContractService, private requestService: RequestService, private httpProdcutService:ProductService , private  toastr:ToastrService , private formService :FormService) { 
   this.certificateForm = this.fb.group({
    shippingLine: [{ disabled: true, value: ''}, Validators.required],
    bookingNumber: ["", Validators.required],
    containerNumber: ["", Validators.required],
    prePaid: ["", Validators.required],
    shipper: ["", Validators.required],
    vessel: ["", Validators.required],
    voyage: ["", Validators.required],
    OportOfLoading: ['', Validators.required],
    portOfDischarge: ['', Validators.required],
    containerType: ['', Validators.required],
    containerQuantity: [0, Validators.required],
    grossWeightContainer: ["", Validators.required],
    notifyParty: [{ value: '', disabled: true }, Validators.required],
    cosignee: [{ value: '', disabled: true }, Validators.required],
    notes: ['', Validators.required],
    HSCode: [{ value: '', disabled: true }, Validators.required],
    dayCut: [0, Validators.required],
    bookingDate: [Date, Validators.required],
    estimatedArrival: ['', Validators.required],
    estimatedDeparture: ['', Validators.required],
    units: [{value: '', disabled: true}],
  });
  }
onSubmit(){
  const {Key, containerType, ...rest} = this.deal
  const body  = {
    ...rest,
       containerType: containerType + ' certificateGenerated',
    portOfDischarge:this.deal.portOfDischarge.split("#")[0] + "#" ,
    ...this.extraFeilds

  }
  this.loading = true
this.formService.updateDealById(this.rFQId, body).subscribe((data: any)=>{
  if(data){
    this.loading = false
  }
  this.toastr.success("Booking Confirmation Sent")
  this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
})
}
onReject(){
  this.cancelLoading = true
  this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
    if(data){
      this.cancelLoading = false
    }
    this.cancelRFQ(this.rFQId, data)
  })
}
cancelRFQ(rfQId, request){
  const {rFQId,myProduct, ...rest} = request
  this.loading = true
  this.requestService.updateStatusRequest(rfQId,{...rest,status:'reject'}).subscribe((data: any)=>{
    if(data){
      this.loading = false
      this.router.navigate(['/dash','requests'])
      this.toastr.success("Rejected Deal Successfully")
    }
  })
}
getDataWithoutHash(seller) {
  return seller.split('#')[1];
} 
setExporter(seller){
  this.contractService.getUser(this.getDataWithoutHash(seller)).subscribe((data: any)=>{
  this.sellerName = data.name
  })
}
setShippingLine(shipper = this.shippingLine){
  this.contractService.getShipper(this.getDataWithoutHash(shipper)).subscribe((data: any)=>{
  this.shipperName = data.name
  })
}
setImporter(buyer) {
  this.contractService.getUser(this.getDataWithoutHash(buyer)).subscribe((data: any)=>{
  this.buyerName = data.name
  })
}
onAccept(){
  let id = this.deal.Key
  var status= this.deal.status
  const {Key, ...rest} = this.deal
 const body = {
    $class: "org.acme.supply_chain_network.FinalizeDeal",
    deal: {status:'Finished', ...this.deal},
    transactionId: this.deal.transactionId,
    timestamp: new Date().toISOString()
  }
  this.acceptLoading = true
  if(this.userData.accountType==='buyer' && !this.deal.containerType.split(' ').includes('COOACCEPTEDBUYER')){
    console.log("Buyer Check Certicfuicate")
    this.contractService.updateDealById(id, { ...rest, containerType:this.deal.containerType + ' COOACCEPTEDBUYER', rFQ:`org.acme.supply_chain_network.RFQ#${this.rFQId}`}).subscribe((data: any) => {
      if(data)
        this.acceptLoading = false
        this.toastr.success("Deal is successfully Accepted")
        this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
      })
  }else if(this.userData.accountType==='government' && !this.deal.containerType.split(' ').includes('COOACCEPTEDGOVERNMENT')){
    console.log("Government Check Certic=fuicate")
    this.contractService.updateDealById(id, { ...rest,containerType:this.deal.containerType + ' COOACCEPTEDGOVERNMENT',  rFQ:`org.acme.supply_chain_network.RFQ#${this.rFQId}`}).subscribe((data: any) => {
      if(data)
        this.acceptLoading = false
        this.toastr.success("Deal is successfully Accepted")
        this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
      })
  }
  else{
    // this.contractService.updateDealById(id, { ...rest, status:'Finished', rFQ:`org.acme.supply_chain_network.RFQ#${this.rFQId}`}).subscribe((data: any) => {
    //   if(data)
    //     this.acceptLoading = false
    //     this.toastr.success("Deal is successfully Finished")
    //     this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
    //   })
  }
  this.acceptLoading = true
  this.contractService.dealFinished(body).subscribe((data: any) => {
    if(data){
      this.acceptLoading = false
    }
  })
}
getReversedFormatDate(){
  const currentDate = new Date()
  let dd = String(currentDate. getDate()). padStart(2, '0');
  let mm = String(currentDate. getMonth() + 1). padStart(2, '0');
  var yyyy = currentDate. getFullYear();
  return  dd + '-' + mm + '-' + yyyy;
}
  ngOnInit(): void {
    this.formLoading = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
      this.request = data
      this.formLoading = false
      this.setExporter(data.seller)
      this.setImporter(data.buyer)
      this.setShippingLine()
      this.httpProdcutService.getSingleProductApi(data.product.split('#')[1]).subscribe((data: any)=>{
        this.product = data
        this.certificateForm.get('notes').setValue(data.notes.split('|')[0])
      })
    })
    this.contractService.getDealById(this.rFQId).subscribe((data: any)=>{
      this.deal = data
      // this.extraFeilds = JSON.parse(data.portOfDischarge.split('#')[1])
      const {
        vessel,
        voyage,
        grossWeight,
        netWeightContainer,
        netweight,
        totalNetWeight,
        totalGrossWeight,
        grossWeightContainer
      } = data
      this.extraFeilds =  {
      vessel,
      voyage,
      grossWeight,
      netweight,
      netWeightContainer,
      totalNetWeight,
      totalGrossWeight,
      grossWeightContainer
    }

          this.certificateForm.get('estimatedArrival').disable()

           this.certificateForm.get('estimatedArrival').setValue(this.getReversedFormatDate())
           this.certificateForm.get('containerQuantity').setValue(data.containerQuantity)
           this.certificateForm.get('containerQuantity').setValue(data.containerQuantity)
           this.certificateForm.get('dayCut').setValue(data?.dayCut)
           this.certificateForm.get('notifyParty').setValue(data?.buyer.split('#')[1])
           this.certificateForm.get('cosignee').setValue(data?.seller.split('#')[1])
           this.certificateForm.get('bookingNumber').setValue(this.rFQId)
           this.certificateForm.get('shipper').setValue(data?.buyer.split('#')[1] | this.request.buyer.split('#')[1])
           this.certificateForm.get('shippingLine').setValue(data?.shippingLine.split('#')[1])
           this.certificateForm.get('HSCode').setValue(data?.product.split('#')[1])
           this.certificateForm.get('containerQuantity').disable()
           this.certificateForm.get('containerType').disable()
           this.certificateForm.get('notes').disable()
           this.certificateForm.get('dayCut').disable()
           this.certificateForm.get('bookingNumber').disable()

    })
  }
}
