import { Component, OnInit ,NgModule, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as EventEmitter from 'events';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/views/add-product/services/product-service';
import { FormService } from 'src/app/views/all-forms/services/formService';
import { RequestService } from 'src/app/views/my-request/services/requests-service';
import {vessel,voyage, totalGrossWeight, containerNumber, prePaid, GrossWeightContainer, bookingNumber, totalNetWeight} from "../../forms-js-variables/forms-varibles"
import { ContractService } from '../contract-form/services/contract-service';

@Component({
  selector: 'app-lading-form',
  templateUrl: './bill-lading-form.html',
  styleUrls: ['./bill-lading-form.scss']
})
export class BillLadingFormComponent implements OnInit {
  formLoading:Boolean = false
  loading:Boolean = false
  cancelLoading:Boolean = false
  acceptLoading:Boolean = false
  shipper
  shipperName
  extraFeilds  = {
    vessel:"",
    voyage:"",
    grossWeight:"",
    netweight:"",
    totalNetWeight:"",
    totalGrossWeight:"",
    grossWeightContainer:"",
    netWeightContainer:""
  }
  formsJSVAR = {
    vessel,
    bookingNumber,
    voyage,
    totalGrossWeight,
    containerNumber,
    prePaid,
    GrossWeightContainer,
    totalNetWeight
  }
  sellerName
  buyerName
  shippingLine:any= "org.acme.supply_chain_network.ShippingLine#4972"
  @Input() rFQId;
  deal:any
  request:any
  billLadingForm: FormGroup
  userData = JSON.parse(localStorage.getItem('userData'));
  checkHandlingNav = ''
  UNITS=['g', 'Kg', 'MT']

  constructor(private router:Router, private fb:FormBuilder,private contractService: ContractService, private requestService: RequestService, private httpProdcutService:ProductService , private  toastr:ToastrService , private formService :FormService) { 

    this.router.events.subscribe(() => {
      this.checkHandlingNav = router.url.split("/")[2]
   });
   this.billLadingForm = this.fb.group({
    shippingLine: [{ disabled: true, value: ''}, Validators.required],
    bookingNumber: ["", Validators.required],
    containerNumber: ["", Validators.required],
    prePaid: ["", Validators.required],
    shipper: ["", Validators.required],
    vessel: ["", Validators.required],
    voyage: ["", Validators.required],
    OportOfLoading: ['', Validators.required],
    portOfDischarge: ['', Validators.required],
    containerType: ['', Validators.required],
    containerQuantity: [0, Validators.required],
    grossWeightContainer: ["", Validators.required],
    notifyParty: [{ value: '', disabled: true }, Validators.required],
    cosignee: [{ value: '', disabled: true }, Validators.required],
    notes: ['', Validators.required],
    HSCode: [{ value: '', disabled: true }, Validators.required],
    dayCut: [0, Validators.required],
    bookingDate: [Date, Validators.required],
    estimatedArrival: [{ value: '', disabled: true }, Validators.required],
    estimatedDeparture: ['', Validators.required],
    units: [{value: '', disabled: true}],
  });
  
  }
  getDataWithoutHash(seller) {
    console.log(seller, "this sithe ")
    return seller.split('#')[1];
  } 
  setExporter(seller){
    console.log(seller, "this is the seller")
    this.contractService.getUser(this.getDataWithoutHash(seller)).subscribe((data: any)=>{
    this.sellerName = data.name
    })
  }
  setShippingLine(shipper = this.shippingLine){
    console.log(shipper, "this is the shipper")

    this.contractService.getShipper(this.getDataWithoutHash(shipper)).subscribe((data: any)=>{
    this.shipperName = data.name
    })
  }
  setImporter(buyer) {
    console.log(buyer, "this is the buyer")
    
    this.contractService.getUser(this.getDataWithoutHash(buyer)).subscribe((data: any)=>{
    this.buyerName = data.name
    })
  }
  onSubmit(){
    const {Key,containerType, ...rest} = this.deal
    const body  = {
      ...rest,
    containerType: containerType + ' billGenerated',
    portOfDischarge:this.deal.portOfDischarge.split("#")[0] + "#",
    ...this.extraFeilds
    }
    const shipmentBody = {
      $class: "org.acme.supply_chain_network.StartShipment",
      deal:{
        ...this.deal,
        containerType: containerType + ' billGenerated', 
        status:"dealApproved",
        portOfDischarge:this.deal.portOfDischarge.split("#")[0] + " ",
        ...this.extraFeilds
      }
    }
    this.loading = true
    this.contractService.updateDealById(this.rFQId, body).subscribe((data: any)=>{
      if(data){
        this.loading = false
        this.toastr.success("Bill of lading generated Successfully")
        this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
      }
    })
    this.contractService.postToShipmentStarted(shipmentBody).subscribe((data: any)=>{
      if(data){
      }
    })
  }
  onAccept(){
    if(this.userData.accountType == 'shipper' && this.deal.containerType.split(' ').includes('buyerAccepted') || this.deal.containerType.split(' ').includes('buyerAccepted')){
      let id = this.deal.Key
      const {Key, ...rest} = this.deal
      this.acceptLoading = true
      let status = ''
      status = 'shipmentStarted'
      this.contractService.updateDealById(id, { ...rest, status:'shipmentStarted',containerType: this.deal.containerType + " shippingAccepted", rFQ:`org.acme.supply_chain_network.RFQ#${this.rFQId}`}).subscribe((data: any) => {
      if(data){
        this.acceptLoading = false
        this.toastr.success("Successfully Accepted the form")
        this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
      }
      })
    }else if(this.userData.accountType == 'buyer' && !this.deal.containerType.split(' ').includes('buyerAccepted')){
      const {Key,containerType, ...rest} = this.deal
      const body  = {
      containerType: containerType + ' buyerAccepted',
           ...rest
      }
      this.loading = true
      console.log("Buyer Check")
      this.contractService.updateDealById(this.rFQId, body).subscribe((data: any)=>{
        if(data){
          this.loading = false
          this.toastr.success("Bill of Lading Accepted Successfully")
          this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
        }
      })
    }else{
      console.log('No condition is fullfilled')
    }
  }
  onReject(){
    this.cancelLoading = true 
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
      if(data){
        this.cancelLoading = false
      }
      this.cancelRFQ(this.rFQId, data)
    })
  }
  cancelRFQ(rfQId, request){
    const {rFQId,myProduct, ...rest} = request
    this.cancelLoading = true 
    this.requestService.updateStatusRequest(rfQId,{...rest,status:'reject'}).subscribe((data: any)=>{

      if(data){
        this.cancelLoading = false
        this.router.navigate(['/dash','requests'])
        this.toastr.success("Rejected Deal Successfully")
    
      }
    })
  }
  getReversedFormatDate(){
    const currentDate = new Date()
    let dd = String(currentDate. getDate()). padStart(2, '0');
    let mm = String(currentDate. getMonth() + 1). padStart(2, '0');
    var yyyy = currentDate. getFullYear();
    return  dd + '/' + mm + '/' + yyyy ;
  }
  getNextMonthDate(date){
    let newDate 
    let nDate = new Date(date)
    newDate = new Date(nDate.getTime()+30*24*60*60*1000); 
    let ldate = newDate.getDate()
    let lmonth = newDate.getMonth() + 1
    let lyear = newDate.getFullYear()
    return ldate + '-' + lmonth + '-' + lyear
   }
  ngOnInit(): void {
    this.formLoading = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
      this.request = data
      this.setShippingLine()
      this.setImporter(data.buyer)
      this.setExporter(data.seller)
      this.formLoading = false
      this.billLadingForm.get('estimatedArrival').setValue(this.getNextMonthDate(data.date))
      this.httpProdcutService.getSingleProductApi(data.product.split('#')[1]).subscribe((data: any)=>{
        this.billLadingForm.get('notes').setValue(data.notes.split('|')[0])
      }) 
    })
    this.contractService.getDealById(this.rFQId).subscribe((data: any)=>{
      this.deal = data
      
      if(this.deal){
        this.formLoading = false
        // this.extraFeilds = JSON.parse(data.portOfDischarge.split('#')[1])
        const {
          vessel,
          voyage,
          grossWeight,
          netWeightContainer,
          netweight,
          totalNetWeight,
          totalGrossWeight,
          grossWeightContainer
        } = data
        this.extraFeilds =  {
        vessel,
        voyage,
        grossWeight,
        netweight,
        netWeightContainer,
        totalNetWeight,
        totalGrossWeight,
        grossWeightContainer
      }
      }
      this.formService.getShipper(this.deal.shippingLine.split('#')[1]).subscribe((data: any)=>{
        this.shipper = data
      })
      console.log(data,'This is the ')
  
           this.billLadingForm.get('estimatedArrival').disable()
           this.billLadingForm.get('containerQuantity').setValue(data.containerQuantity)
           this.billLadingForm.get('containerQuantity').setValue(data.containerQuantity)
           this.billLadingForm.get('dayCut').setValue(data?.dayCut)
           this.billLadingForm.get('notifyParty').setValue(data?.buyer.split('#')[1])
          //  this.billLadingForm.get('cosignee').setValue(data?.seller.split('#')[1])
           this.billLadingForm.get('bookingNumber').setValue(this.rFQId)
           this.billLadingForm.get('shipper').setValue(data?.buyer.split('#')[1])
           this.billLadingForm.get('shippingLine').setValue(data?.shippingLine.split('#')[1])
           this.billLadingForm.get('HSCode').setValue(data?.product.split('#')[1])
           this.billLadingForm.get('containerQuantity').disable()
           this.billLadingForm.get('containerType').disable()
           this.billLadingForm.get('notes').disable()
           this.billLadingForm.get('dayCut').disable()
           this.billLadingForm.get('bookingNumber').disable()


    })
  }
}
