<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
 </div> 
<form [formGroup]="commercialForm" (ngSubmit)="onSubmit()" >
  <div class="row d-flex justify-content-end">
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
            <label class="label">Invoice Number</label>
            <input
              disabled
              type="number"
              class="form-control"
              placeholder="00000000000000"
              value="{{rFQId}}"
              disabled
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Invoice date</label>
          <input
              class="form-control "
              placeholder="Select Date"
              disabled
              value="{{getReversedFormatDate()}}"
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Exporter</label>
          <input
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              value="{{sellerName}}"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
          <label class="label">Importer</label>
          <input
              disabled
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              value="{{buyerName}}"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="" class="label ">Description of goods</label>
            <textarea
            type="text"
            class="form-control"
            placeholder="Lorem Ipsum"
            rows="1"              
            formControlName="notes"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"

          ></textarea>
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group double-arrow">
          <label class="label">Quantity</label>
          <input
            type="number"
            class="form-control"
            placeholder="Select Quantity"
            value="{{request?.quantity}}"
            disabled
            />
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Unit</label>
            
              <select [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"  class="form-control" formControlName="units">
              <option selected   value="" >{{request?.units}}</option>
          <option *ngFor="let unit of UNITS">
          {{unit}}
          </option>
          </select> 
      </div>
      </div>
    <div class="col-12 col-lg-6">
      <div class="form-group double-arrow">
        <label class="label">Net Weight</label>
        <input
          type="number"
          class="form-control"
          placeholder="10"
          name="extraFeilds.netweight"
          [(ngModel)]="extraFeilds.netweight"
          [ngModelOptions]="{standalone: true}"
          [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"
        />
      </div>
    </div>
      <div class="col-12 col-lg-6">
          <div class="form-group ">
          <label class="label">Gross Weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              value="{{formsJSVAR.totalGrossWeight}}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"
              name="extraFeilds.grossWeight"
              [(ngModel)]="extraFeilds.grossWeight"
              [ngModelOptions]="{standalone: true}"  
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Unit price (USD)</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              value="{{product?.unitPrice}}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">TOTAL price</label>
          <input
              type="number"
              class="form-control"
              placeholder="100"
              value="{{deal?.finalPrice}}"
              disabled
              />
            </div>
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label for="" class="label">INCO Term</label>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
          <select disabled class="form-control ">
            <option selected value="">{{request?.shippingTerms[0]}}</option>
            <option *ngFor="let value of INCO_LIST" [value]="value">
              {{ value }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Contract Number</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              value="{{formsJSVAR.bookingNumber}}"
              disabled
              />
            </div>
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total net weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalNetWeight"
              [(ngModel)]="extraFeilds.totalNetWeight"
              [ngModelOptions]="{standalone: true}"  
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total gross weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalGrossWeight"
              [(ngModel)]="extraFeilds.totalGrossWeight"
              [ngModelOptions]="{standalone: true}"  
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')"
              
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
              <label class="label">HS CODE</label>
              <input
                  disabled
                  type="number"
                  class="form-control"
                  placeholder="10"
                  value="{{product?.HSCode}}"
                  disabled
                  />
                </div>
              </div>
              <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated' || request?.product)" -->
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Container Quantity</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              value="{{deal?.containerQuantity}}"
              disabled
              />
            </div>
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Bank Name</label>
          <input
              type="text"
              class="form-control"
              value="{{bankName}}"
              placeholder="Bank Name"
              disabled
              />
            </div>
          </div>
          <!-- [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('incvoiceGenerated')" -->
      <div *ngIf="(userData.accountType == 'seller' && !deal?.containerType.split(' ').includes('incvoiceGenerated')) || (userData.accountType == 'buyer' && deal?.status !== 'shipped')" class="col-md-7 request-quotation-form-btn text-end">
        <button (click)="onReject()"  *ngIf="userData.accountType == 'buyer' " mat-button class="btn btn-outline-secondary card-btn-secondary" >
        <span *ngIf="cancelLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Cancel Commercial Invoice
      </button>
        <button *ngIf="userData.accountType == 'buyer' " type="button" (click)="onAccept()" mat-button class="card-btn">
        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Approve Commercial Invoice 
        </button>
        <button *ngIf="userData.accountType == 'seller'"  mat-button class="card-btn"  type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
         Generate Commercial Invoice 
       </button>
      </div>
  </div>
</form>