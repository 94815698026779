import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContractService } from '../contract-form/services/contract-service';

@Component({
  selector: 'app-proceed-payment-button',
  templateUrl: './proceed-payment-button.component.html',
  styleUrls: ['./proceed-payment-button.component.scss']
})
export class ProceedPaymentButtonComponent implements OnInit {
  @Input() dealId
  deal
  constructor(private dealApi:ContractService, private toastr: ToastrService,private router:Router) { }

  ngOnInit(): void {
    this.dealApi.getDealById(this.dealId).subscribe((data) => {
      this.deal = data
    })
  }
finalizeDeal(e){
  e.disaled = true
  const {Key, ...rest}= this.deal
  this.dealApi.updateDealById(this.dealId,{...rest, status:'Finished'}).subscribe((data) => {
    this.router.navigate(['/dash', 'requests'])
    this.toastr.success("Deal is finished successfully")
  })
}
}
