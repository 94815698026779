import { Component, OnInit ,NgModule } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  userData = JSON.parse(localStorage.getItem('userData'));
  checkHandlingNav = ''
  constructor(private router:Router) { 
    router.events.subscribe(() => {
      let url = router.url
          if(router.url.split('').includes('?')){
                url = router.url.substr(0, router.url.lastIndexOf("?"))
            }
    this.checkHandlingNav = url.split("/")[2]
   });
  }

  ngOnInit(): void {
  }
}
