import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private baseUrl = environment.baseUrl;
  private headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).authToken}`, // Include any other headers you need
    });
  }

  postContractFormApi(body) {
    return this.httpClient.post(this.baseUrl+"Deal",body, { headers: this.headers });
  }
  getSingleRequestsApi(rfQId) {
    return this.httpClient.get(this.baseUrl + `RFQ/${rfQId}`, { headers: this.headers });
  }
  updateDealById(id,body){
    return  this.httpClient.put(this.baseUrl + `Deal/${id}`, body, { headers: this.headers })
  }
  getShipper(id){
    return  this.httpClient.get(this.baseUrl + `ShippingLine/${id}`, { headers: this.headers })
  }
}