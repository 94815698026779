<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  </div>

<form [formGroup]="certificateForm" (ngSubmit)="onSubmit()" >
  <div class="row d-flex justify-content-end">
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
            <label class="label">Invoice Number</label>
            <input
              disabled
              type="number"
              class="form-control"
              placeholder="0000000"
              value="{{rFQId}}"
              disabled
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Invoice date</label>
          <input
              class="form-control "
              placeholder="Select Date"
              disabled
              value="{{ getReversedFormatDate()}}"
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated')" -->
      </div>
            <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Shipper</label>
        <input
            type="text"
            class="form-control"
            placeholder="WorldImporters"
            value="{{shipperName}}"
            disabled

        />
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <div class="form-group">
        <label class="label">Consignee</label>
        <input
            type="text"
            class="form-control"
            placeholder="WorldImporters"
            value="{{sellerName}}"
            disabled

        />
        </div>
    </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Vessel</label>
          <input
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              name="extraFeilds.vessel"
              [(ngModel)]="extraFeilds.vessel"
              [ngModelOptions]="{standalone: true}"
              disabled
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group disabled">
          <label class="label">Voyage</label>
          <input
              disabled
              type="text"
              class="form-control"
              placeholder="WorldImporters"
              name="extraFeilds.voyage"
              [(ngModel)]="extraFeilds.voyage"
              [ngModelOptions]="{standalone: true}"
              disabled
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group double-arrow">
          <label class="label">HS CODE</label>
          <input
            type="number"
            class="form-control"
            placeholder="Select Quantity"
            value="{{request?.product.split('#')[1]}}"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label for="" class="label ">Description of goods</label>
          <textarea
          type="text"
          class="form-control"
          placeholder="Description"
          rows="1"                  
          [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated') "
          value="{{product?.notes.split('|')[0]}}"                        
          ></textarea>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group double-arrow">
          <label class="label">Bill of Lading Number</label>
          <input
            type="number"
            class="form-control"
            placeholder="10"
            value="{{rFQId}}"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of Loading</label>
          <select disabled class="form-control ">
            <option selected>{{request?.shipeFrom}}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of discharge</label>
          <select disabled class="form-control ">
            <option selected>{{request?.shipeTo}}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group double-arrow">
          <label class="label">Container Quantity</label>
          <input
            type="number"
            class="form-control"
            placeholder="Select Quantity"
            value="{{deal?.containerQuantity}}"
            disabled
            />
            <!-- [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated') " -->
          </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="form-group  arrow-down">
              <label class="label ">Container type</label>
            <select  class="form-control" disabled formControlName="containerType">
                <option selected  >{{deal?.containerType.split(' ')[0]}}</option>
                <option >
               
                </option>
              </select>
            </div>
          </div>
      <div class="col-12 col-lg-6">
          <div class="form-group ">
          <label class="label">Total net Weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalNetWeight"
              [(ngModel)]="extraFeilds.totalNetWeight"
              [disabled]= "userData?.accountType === 'buyer' || userData?.accountType === 'government'"
              [ngModelOptions]="{standalone: true}"              
              />
            </div>
            <!-- [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated') " -->
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total gross weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="10"
              name="extraFeilds.totalGrossWeight"
              [(ngModel)]="extraFeilds.totalGrossWeight"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData.accountType == 'buyer'  || deal?.containerType.split(' ').includes('certificateGenerated')"

          />
          </div>
      </div>
      <div *ngIf="(userData.accountType == 'buyer' || userData.accountType == 'government' && deal?.status !== 'Finished') || (userData.accountType == 'seller' && !deal?.containerType.split(' ').includes('certificateGenerated'))" class="col-md-7 request-quotation-form-btn text-end">
        <button *ngIf="userData.accountType !== 'seller'" (click)="onReject()"  mat-button class="btn btn-outline-secondary card-btn-secondary" type="button">
        <span *ngIf="cancelLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Cancel  Certificate Origin
      </button>
        <button *ngIf="userData.accountType !== 'seller' && (userData.accountType == 'buyer' && !deal?.containerType.split(' ').includes('COOACCEPTEDBUYER')) " type="button"  (click)="onAccept()" mat-button class="card-btn">
          <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
             Approve Certificate Origin
          </button>
          <button *ngIf="userData.accountType !== 'seller' && (userData.accountType == 'government' && !deal?.containerType.split(' ').includes('COOACCEPTEDGOVERNMENT')) " type="button"  (click)="onAccept()" mat-button class="card-btn">
            <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
               Issue Certificate Origin
            </button>
        <button *ngIf="userData.accountType == 'seller'"  mat-button class="card-btn"  type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           Generate Certificate Origin
       </button>
      </div>
  </div>
</form>