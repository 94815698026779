import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  userData = JSON.parse(localStorage.getItem('userData'));
  companyName = JSON.parse(localStorage.getItem("userData"))?.name
  checkHandlingNav = ''
  constructor(private router: Router) {
    if (!this.userData) {
      this.router.navigate(['/dash', 'login']);
    }
    this.router.events.subscribe(() => {
      this.checkHandlingNav = router.url.split("/")[2]
    });
    console.log(this.userData, "THisdnonsij")
  }
  navigateToHome() {
    if (this.userData.accountType == 'seller') {
      this.router.navigate(['/dash', 'my-product'])
    } else if (this.userData.accountType == 'government') {
      this.router.navigate(['/dash', 'requests'])
    }
    else if (this.userData.accountType == 'bank') {
      this.router.navigate(['/dash', 'requests'])
    }
    else if (this.userData.accountType == 'admin') {
      this.router.navigate(['/dash', 'requests'])
    }
    else {
      this.router.navigate(['/dash', 'home'])
    }
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['/auth', 'login'])
  }

  ngOnInit(): void {
  }
}