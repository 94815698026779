import { Component, OnInit , Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/views/add-product/services/product-service';
import { FormService } from 'src/app/views/all-forms/services/formService';
import { RequestService } from 'src/app/views/my-request/services/requests-service';
import { ContractService } from '../contract-form/services/contract-service';
import {vessel,voyage, totalGrossWeight, containerNumber, prePaid, GrossWeightContainer, bookingNumber, totalNetWeight} from "../../forms-js-variables/forms-varibles"


@Component({
  selector: 'app-commercial-form',
  templateUrl: './commercial-invoice-form.html',
  styleUrls: ['./commercial-invoice-form.scss']
})
export class CommercialFormComponent implements OnInit {
  formsJSVAR = {
    vessel,
    bookingNumber,
    voyage,
    totalGrossWeight,
    containerNumber,
    prePaid,
    GrossWeightContainer,
    totalNetWeight
  }
  extraFeilds  = {
    vessel:"",
    voyage:"",
    grossWeight:"",
    netweight:"",
    totalNetWeight:"",
    totalGrossWeight:"",
    grossWeightContainer:"",
    netWeightContainer:""
  }
  shipperName
  sellerName
  bankName
  buyerName
  shippingLine:any= "org.acme.supply_chain_network.ShippingLine#4972"
  formLoading:Boolean = false
  @Input() rFQId;
  deal
  request: any
  INCO_LIST = ['FOB', 'FCA', 'EXW'];
  commercialForm: FormGroup
  userData = JSON.parse(localStorage.getItem('userData'));
  checkHandlingNav = ''
  UNITS=['g', 'Kg', 'MT']
  product:any
  loading: boolean = false
  acceptLoading: boolean = false
  cancelLoading: boolean = false
  constructor(private router:Router, private fb:FormBuilder,private contractService: ContractService, private requestService: RequestService, private httpProdcutService:ProductService , private  toastr:ToastrService , private formService :FormService) { 

    this.router.events.subscribe(() => {
      this.checkHandlingNav = router.url.split("/")[2]
   });
   this.commercialForm = this.fb.group({
    shippingLine: [{ disabled: true, value: ''}, Validators.required],
    bookingNumber: ["", Validators.required],
    containerNumber: ["", Validators.required],
    prePaid: ["", Validators.required],
    shipper: ["", Validators.required],
    vessel: ["", Validators.required],
    voyage: ["", Validators.required],
    OportOfLoading: ['', Validators.required],
    portOfDischarge: ['', Validators.required],
    containerType: ['', Validators.required],
    containerQuantity: [0, Validators.required],
    grossWeightContainer: ["", Validators.required],
    notifyParty: [{ value: '', disabled: true }, Validators.required],
    cosignee: [{ value: '', disabled: true }, Validators.required],
    notes: ['', Validators.required],
    HSCode: [{ value: '', disabled: true }, Validators.required],
    dayCut: [0, Validators.required],
    bookingDate: [Date, Validators.required],
    estimatedArrival: ['', Validators.required],
    estimatedDeparture: ['', Validators.required],
    units: [{value: '', disabled: true}],
  });
  
  }
  onSubmit(){
    const {Key,containerType, ...rest} = this.deal
    const body  = {
      ...rest,
    containerType: containerType + ' incvoiceGenerated',
    portOfDischarge:this.deal.portOfDischarge.split("#")[0] + "#",
    ...this.extraFeilds
    }
    this.loading = true
    console.log(body, "This is the body")
    this.contractService.updateDealById(this.rFQId, body).subscribe((data: any)=>{
      if(data){
        this.loading = false
        this.toastr.success("Commercial Form Successfully")
        this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
      }
    })
  }
  onAccept(){
    let id = this.deal.Key
    const {Key, ...rest} = this.deal
    this.acceptLoading = true
    this.contractService.updateDealById(id, { ...rest, status:'shipped', containerType:this.deal.containerType + " commercialAccepted", rFQ:`org.acme.supply_chain_network.RFQ#${this.rFQId}`}).subscribe((data: any) => {
    if(data){
      this.acceptLoading = false
      this.toastr.success("Successfully Accepted the form")
      this.router.navigate(['/dash','my-request'],{queryParams:{view:'documents', rFQId:this.rFQId}})
    }
    })
  }
  onReject(){
    this.cancelLoading  = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
      this.cancelRFQ(this.rFQId, data)
    })
  }
  cancelRFQ(rfQId, request){
    const {rFQId,myProduct, ...rest} = request
    this.requestService.updateStatusRequest(rfQId,{...rest,status:'reject'}).subscribe((data: any)=>{
      if(data){
        this.cancelLoading = false
        this.router.navigate(['/dash','requests'])
        this.toastr.success("Rejected Deal Successfully")
    
      }
    })
  }
  getReversedFormatDate(){
    const currentDate = new Date()
    let dd = String(currentDate. getDate()). padStart(2, '0');
    let mm = String(currentDate. getMonth() + 1). padStart(2, '0');
    var yyyy = currentDate. getFullYear();
    return  dd + '-' + mm + '-' + yyyy;
  }
    getDataWithoutHash(seller) {
      return seller.split('#')[1];
    } 
    setExporter(seller){
      this.contractService.getUser(this.getDataWithoutHash(seller)).subscribe((data: any)=>{
      this.sellerName = data.name
      })
    }
    setShippingLine(shipper = this.shippingLine){
      this.contractService.getShipper(this.getDataWithoutHash(shipper)).subscribe((data: any)=>{
      this.shipperName = data.name
      })
    }
    setImporter(buyer) {
      this.contractService.getUser(this.getDataWithoutHash(buyer)).subscribe((data: any)=>{
      this.buyerName = data.name
      })
    }
    getBankName(bank){
      this.contractService.getBankName(bank).subscribe((res: any)=>{
      this.bankName= res.name
      })
    }
  ngOnInit(): void {
    this.formLoading = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any)=>{
      this.getBankName(data.bank.split('#')[1])
      console.log(data, "this is the data")
      this.request = data
      this.setExporter(data.seller)
      this.setImporter(data.buyer)
      this.formLoading = false
      this.httpProdcutService.getSingleProductApi(data.product.split('#')[1]).subscribe((data: any)=>{
        this.product = data
        this.commercialForm.get('notes').setValue(data.notes.split('|')[0])
      })
    })
    this.formLoading = true
    this.contractService.getDealById(this.rFQId).subscribe((data: any)=>{
      this.deal = data

      if(this.deal){
      this.formLoading = false
      console.log(data.portOfDischarge, "This is the portdischarge")
      // this.extraFeilds = JSON.parse(data.portOfDischarge.split('#')[1])
      const {
        vessel,
        voyage,
        grossWeight,
        netWeightContainer,
        netweight,
        totalNetWeight,
        totalGrossWeight,
        grossWeightContainer
      } = data
      this.extraFeilds =  {
      vessel,
      voyage,
      grossWeight,
      netweight,
      netWeightContainer,
      totalNetWeight,
      totalGrossWeight,
      grossWeightContainer
    }
      }
      // this.formService.getShipper(this.deal).subscribe((data: any)=>{
      //   this.shippingLine = data
      // })

           this.commercialForm.get('estimatedArrival').disable()
           this.commercialForm.get('estimatedArrival').setValue(this.getReversedFormatDate())
           this.commercialForm.get('containerQuantity').setValue(data.containerQuantity)
           this.commercialForm.get('containerQuantity').setValue(data.containerQuantity)
           this.commercialForm.get('dayCut').setValue(data?.dayCut)
           this.commercialForm.get('notifyParty').setValue(data?.buyer.split('#')[1])
           this.commercialForm.get('cosignee').setValue(data?.seller.split('#')[1])
           this.commercialForm.get('bookingNumber').setValue(this.rFQId)
           this.commercialForm.get('shipper').setValue(data?.buyer.split('#')[1])
           this.commercialForm.get('shippingLine').setValue(data?.shippingLine.split('#')[1])
           this.commercialForm.get('HSCode').setValue(data?.product.split('#')[1])
           this.commercialForm.get('containerQuantity').disable()
           this.commercialForm.get('containerType').disable()
           this.commercialForm.get('notes').disable()
           this.commercialForm.get('dayCut').disable()
           this.commercialForm.get('bookingNumber').disable()

    })
  }
}
