    <div *ngIf="userData.accountType !== 'admin'"  class="side-nav wrap-radius">
        <ul class="ul">
            <li [routerLink]="['/dash', 'requests']" [class.hover]="checkHandlingNav == 'my-request' || checkHandlingNav == 'documents-form' || checkHandlingNav == 'requests'">
                <a >
                    My Requests
                </a>
            </li>
            <li *ngIf="userData.accountType == 'seller'" [routerLink]="['/dash', 'my-product']" [class.hover]="checkHandlingNav == 'my-product' || checkHandlingNav == 'add-product'">
                <a >
                    My Products
                </a>
            </li>
        </ul>
    </div>
    <div *ngIf="userData.accountType == 'admin'" class="side-nav wrap-radius">
        <ul class="ul">
            <li [routerLink]="['/dash', 'admin-dashboard']" class="{{checkHandlingNav == 'admin-dashboard' && 'hover'}}">
                <a >
                    All Deals
                </a>
            </li>
            <li [routerLink]="['/dash', 'registration-requests']" class="{{checkHandlingNav == 'registration-requests' && 'hover'}}">
                <a>
                    Registration Requests
                </a>
            </li>
        </ul>
    </div>
