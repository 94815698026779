<div *ngIf="formLoading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<form [formGroup]="billLadingForm" (ngSubmit)="onSubmit()" >
  <div class="row d-flex justify-content-end">
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="label">Bill of Lading number</label>
            <input
              type="number"
              class="form-control"
              placeholder="00000000000"
              value="{{request?.rFQId}}"
              disabled
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="label">Booking number</label>
            <input
              type="text"
              class="form-control"
              placeholder="000000000000"
              value="yz903689"
              disabled
            />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Shipping line</label>
          <input
              type="text"
              class="form-control"
              placeholder="Shipping line"
              value="{{shipperName}}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"
              />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Shipper</label>
          <input
              type="text"
              class="form-control"
              placeholder="Shipper"
              value="{{sellerName}}"
              disabled

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Consignee</label>
          <input
              type="text"
              class="form-control"
              placeholder="Consignee"
              value="{{buyerName}}"
              disabled

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Notify Party</label>
          <input
              type="text"
              class="form-control"
              placeholder="Notify Party"
              value="{{buyerName}}"

              disabled

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="" class="label ">Description of goods</label>
            <textarea
            type="text"
            class="form-control"
            placeholder="Description"
            rows="1"      
            disabled
            formControlName="notes"                                    
          ></textarea>
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Vessel</label>
          <input
              type="text"
              class="form-control"
              placeholder="Vessel"
              [(ngModel)]="extraFeilds.vessel"
              [ngModelOptions]="{standalone: true}"
              [disabled]="userData?.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Voyage</label>
          <input
              type="text"
              class="form-control"
              placeholder="Voyage"
              [disabled]="userData?.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"
              [(ngModel)]="extraFeilds.voyage"
              [ngModelOptions]="{standalone: true}"
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of Loading</label>
          <select disabled  formControlName="OportOfLoading" class="form-control ">
            <option disabled selected disabled value="" >{{request?.shipeFrom}}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Port of discharge</label>
          <select disabled formControlName="portOfDischarge"  class="form-control ">
            <option selected value="" >{{request?.shipeTo}}</option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group arrow-down">
          <label class="label">Container type</label>
          <select disabled  class="form-control ">
            <option selected > {{deal?.containerType.split(' ')[0] }}  </option>
            <option>
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Container number</label>
          <input
              type="number"
              disabled
              class="form-control"
              placeholder="Container number"
              value="{{formsJSVAR.bookingNumber}}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">HS CODE</label>
          <input
              type="number"
              class="form-control"
              disabled
              placeholder="00"
              formControlName="HSCode"
              value="{{request?.product.split('#')[1]}}"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total net weight</label>
          <input
              type="text"
              class="form-control"
              placeholder="Total net weight"
              [disabled]= "(userData?.accountType === 'buyer' || userData?.accountType === 'government') || (userData?.accountType === 'seller' && deal?.containerType.split(' ').includes('buyerAccepted')) || (userData?.accountType === 'shipper' && deal?.containerType.split(' ').includes('shippingAccepted'))"
              name="extraFeilds.totalNetWeight"
              [(ngModel)]="extraFeilds.totalNetWeight"
              [ngModelOptions]="{standalone: true}"
              />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Total gross weight</label>
          <input
              type="number"
              class="form-control"
              placeholder="Total gross weight"
              [disabled]= "(userData?.accountType === 'buyer' || userData?.accountType === 'government') || (userData?.accountType === 'seller' && deal?.containerType.split(' ').includes('buyerAccepted')) || (userData?.accountType === 'shipper' && deal?.containerType.split(' ').includes('shippingAccepted'))"
              name="extraFeilds.totalGrossWeight"
              [(ngModel)]="extraFeilds.totalGrossWeight"
              [ngModelOptions]="{standalone: true}"    
              />

          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Net weight/container</label>
          <input
              type="number"
              class="form-control"
              placeholder="Net weight/container"
              [disabled]= "(userData?.accountType === 'buyer' || userData?.accountType === 'government') || (userData?.accountType === 'seller' && deal?.containerType.split(' ').includes('buyerAccepted')) || (userData?.accountType === 'shipper' && deal?.containerType.split(' ').includes('shippingAccepted'))"
              name="extraFeilds.netweight"
              [(ngModel)]="extraFeilds.netweight"
              [ngModelOptions]="{standalone: true}"    
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Gross weight/container</label>
          <input
              type="number"
              class="form-control"
              placeholder="00"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"
              name="extraFeilds.grossWeightContainer"
              [(ngModel)]="extraFeilds.grossWeightContainer"
              [ngModelOptions]="{standalone: true}"    
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group arrow-down">
              <label class="label">Unit</label>
              <select disabled  class="form-control ">
              <option value="">{{request?.units}}</option>
              <option *ngFor="let value of UNITS" >
                  {{value}}
              </option>
              </select>
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Prepaid</label>
          <input
              type="name"
              class="form-control"
              placeholder="Prepaid"
              value="{{formsJSVAR.prePaid}}"
              disabled
          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Container Quantity</label>
          <input
              type="number"
              class="form-control"
              placeholder="Container Quantity"
              formControlName="containerQuantity"
              disabled

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
          <div class="form-group">
          <label class="label">Place of Issue of Waybill</label>
          <input
              type="text"
              class="form-control"
              placeholder="Description"
              value="{{shipper?.address?.street + ', ' + shipper?.address?.city + ', ' + shipper?.address?.country }}"
              [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"

          />
          </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label class="label">Shipped on Board Date</label>
          <input
            class="form-control "
            placeholder="Select Date"
            formControlName="estimatedArrival"
            [disabled]="userData.accountType == 'buyer' || deal?.containerType.split(' ').includes('billGenerated')"
            type="text"
          />
        </div>
      </div>
      <div *ngIf="!deal?.containerType.split(' ').includes('shippingAccepted')" class="col-md-7 request-quotation-form-btn text-end">
        <button (click)="onReject()"  *ngIf="userData.accountType == 'buyer' || userData.accountType == 'shipper'" type="button" mat-button class="btn btn-outline-secondary card-btn-secondary">
        <span *ngIf="cancelLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Cancel Bill of Lading 
      </button>
        <button *ngIf="userData.accountType == 'buyer' && !deal?.containerType.split(' ').includes('buyerAccepted')" type="button" (click)="onAccept()" mat-button class="card-btn">
        <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Approve Bill of Lading 
        </button>
        <button *ngIf="userData.accountType == 'shipper' && !deal?.containerType.split(' ').includes('shippingAccepted') " type="button" (click)="onAccept()" mat-button class="card-btn">
          <span *ngIf="acceptLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Issue Bill of Lading 
          </button>
        <button *ngIf="(userData.accountType == 'seller' && !deal?.containerType.split(' ').includes('billGenerated'))"  mat-button class="card-btn"  type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
         Generate Bill of Lading 
       </button>
      </div>
  </div>
</form>