import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { Product } from 'src/app/shared/models/product.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.baseUrl;
  constructor(
    private httpClient: HttpClient,
  ) {}
  getUsers(){
    return this.httpClient.get(this.baseUrl + `User`);
  }
  updateStatus(id, body){
    return this.httpClient.put(this.baseUrl + `User/${id}`,body);
  }
}