import { formatDate } from '@angular/common';
import { Component, OnInit, NgModule, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/views/add-product/services/product-service';
import { RequestService } from "src/app/views/my-request/services/requests-service"
import { AuthService } from "../../../core/auth/service/auth.service"
import { BankService } from '../../services/bank.service';
import { ContractService } from './services/contract-service';
import { CustomValidators } from 'src/app/views/validators/custom-validator';
@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.html',
  styleUrls: ['./contract-form.scss']
})
export class ContractFormComponent implements OnInit {
  @Input() rFQId;
  @Input() loading;
  loader: Boolean = false
  @Output() formData: EventEmitter<any> = new EventEmitter();
  request: any
  allowForm: Boolean = false
  sellerName
  buyerName
  bankName
  formLoading: Boolean = false
  acceptLoading: Boolean = false
  formValid: Boolean = false
  rejectLoading: Boolean = false
  alreadyExist: Boolean = false
  contractForm: FormGroup
  check: Boolean = false
  extraFeilds = {
    vessel: "",
    voyage: "",
    grossWeight: "",
    netweight: "",
    totalNetWeight: "",
    totalGrossWeight: "",
    grossWeightContainer: "",
    netWeightContainer: ""
  }
  checkHandlingNav = ''
  INCO_LIST = ['FOB', 'FCA', 'EXW'];
  PORTS = ['Port1, Port2', "Port3"]
  containerTypes = ["40FT", "20FT"]
  StartDeal
  bank: any
  userData = JSON.parse(localStorage.getItem('userData'));
  constructor(private router: Router,
    private fb: FormBuilder, private requestService: RequestService, private productService: ProductService, private authService: AuthService, private bankService: BankService, private contractService: ContractService, private toastr: ToastrService) {
    this.contractForm = this.fb.group({
      Key: [{ disabled: true, value: this.rFQId }, Validators.required],
      status: "",
      finalPrice: [0, Validators.required],
      darftContractTerms: ["", Validators.required],
      OportOfLoading: ['', Validators.required],
      portOfDischarge: ['', Validators.required],
      containerType: ['', Validators.required],
      containerQuantity: [0, Validators.required],
      quantity: [{ value: '', disabled: true }, Validators.required],
      importer: [{ value: '', disabled: true }, Validators.required],
      exporter: [{ value: '', disabled: true }, Validators.required],
      exporterAddress: [{ value: '', disabled: true }, Validators.required],
      importerAddress: [{ value: '', disabled: true }, Validators.required],
      notes: [{ value: '', disabled: true }, Validators.required],
      HSCode: [{ value: '', disabled: true }, Validators.required],
      contractDate: [{ value: '', disabled: true }, Validators.required],
      dayCut: [null, [Validators.required, CustomValidators.noAlphabetChars()]],
      bookingDate: [Date, Validators.required],
      timeToDepart: ['', Validators.required],
      timeToArraive: ['', Validators.required],
      rFQ: {},
      shippingLine: {},
      seller: `resource:org.acme.supply_chain_network.User#${''}`,
      buyer: `resource:org.acme.supply_chain_network.User#${this.userData.participantKey}`,
      bank: `resource:org.acme.supply_chain_network.Bank#8620`,
      transactionId: ['', Validators.required],
      timestamp: [, Validators.required],
      unitPrice: [{ value: '', disabled: true }, Validators.required],
      totalPrice: [{ value: '' }, Validators.required],
      preferredArrival: [{ value: '', disabled: true }, Validators.required],
      netweight: [{ value: '', disabled: true }, Validators.required],
      shippingTerms: [{ value: '', disabled: true }, Validators.required],
    });
    this.router.events.subscribe(() => {
      this.checkHandlingNav = router.url.split("/")[2]
    });
  }
  getImporter(buyer) {
    return buyer.split('#')[1];
  }
  handleCheckbox(e) {
    this.allowForm = e.checked
  }
  getExporter(seller) {
    return seller.split('#')[1];
  }

  getBank(id) {
    this.bankService.getSingleBankApi(id).subscribe((data: any) => {
      this.bank = data
    })
  }

  newGuid() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private isFormValid(): boolean {
    const containerType = this.contractForm.get('containerType').value;
    const containerQuantity = this.contractForm.get('containerQuantity').value;
    if (containerType && containerQuantity && !this.contractForm.get('dayCut').errors) {
      return true;
    } else {
      this.toastr.error("Please fill in all required fields.");
      return false;
    }
  }

  onSubmit() {
    if (this.isFormValid()) {
      const body = {
        $class: "org.acme.supply_chain_network.Deal",
        Key: this.rFQId,
        status: "dealApproved",
        finalPrice: Number(this.contractForm.get('totalPrice').value),
        darftContractTerms: [],
        OportOfLoading: this.request.shipeFrom || "Egypt",
        portOfDischarge: this.request.shipeFrom || "Pakistan",
        containerType: `${this.contractForm.get('containerType').value} contractGenerated`,
        containerQuantity: Number(this.contractForm.get('containerQuantity').value),
        dayCut: this.contractForm.get('dayCut').value,
        timeToDepart: "2022-01-10T06:08:55.351Z",
        timeToArraive: this.request.preferredArrival,
        rFQ: `org.acme.supply_chain_network.RFQ#${this.request.rFQId}`,
        bank: `org.acme.supply_chain_network.Bank#${8620}`,
        product: `org.acme.supply_chain_network.Product#${this.request.product.HSCode}`,
        seller: this.request.seller,
        buyer: this.request.buyer,
        shippingLine: "org.acme.supply_chain_network.ShippingLine#4972"
      };

      if (!this.alreadyExist && this.userData.accountType === 'seller') {
        // this.formData.emit({ ...body, portOfDischarge: `${this.request.shipeFrom}${JSON.stringify(this.extraFeilds)}` });
        this.formData.emit({ ...body, portOfDischarge: `${this.request.shipeFrom}`, ...this.extraFeilds });

      } else {
        this.toastr.error("Please fill the form carefully");
      }
    }
  }

  onAccept() {
    let id = this.StartDeal.Key
    const { Key, containerType, ...rest } = this.StartDeal
    this.acceptLoading = true
    this.contractService.updateDealById(id, { ...rest, status: 'dealApproved', containerType: containerType + ' contractAccepted', rFQ: `org.acme.supply_chain_network.RFQ#${this.rFQId}` }).subscribe((data: any) => {
      if (data) {
        this.acceptLoading = false
      }
      this.toastr.success("Successfully Accepted the form")
      this.router.navigate(['/dash', 'my-request'], { queryParams: { view: 'documents', rFQId: this.rFQId } })
    })
  }
  onReject() {
    this.rejectLoading = true
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any) => {
      if (data) {
        this.rejectLoading = false
      }
      this.cancelRFQ(this.rFQId, data)
    })
  }
  cancelRFQ(rfQId, request) {
    const { rFQId, myProduct, ...rest } = request
    this.requestService.updateStatusRequest(rfQId, { ...rest, status: 'reject' }).subscribe((data: any) => {
      if (data) {
        this.router.navigate(['/dash', 'requests'])
        this.toastr.success("Rejected RFQ Successfully")

      }
    })
  }
  getReversedFormatDate() {
    const currentDate = new Date()
    let dd = String(currentDate.getDate()).padStart(2, '0');
    let mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    var yyyy = currentDate.getFullYear();
    return  dd + '/' + mm + '/' + yyyy;
  }
  getThirtyDaysAfterDate(date) {
    let rfqDate = new Date(date)
    const currentDate = rfqDate ? new Date(rfqDate.getTime() + 30 * 24 * 60 * 60 * 1000) : new Date()
    let dd = String(currentDate.getDate())
    let mm = String(currentDate.getMonth() + 1)
    var yyyy = currentDate.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }

  setExporter(seller) {
    this.contractService.getUser(this.getExporter(seller)).subscribe((data: any) => {
      console.log(data, "this is the data from user")
      this.sellerName = data.name
    })
  }

  setImporter(seller) {
    this.contractService.getUser(this.getExporter(seller)).subscribe((data: any) => {
      this.buyerName = data.name
    })
  }
  
  getBankName(bank) {
    this.contractService.getBankName(bank).subscribe((res: any) => {
      this.bankName = res.name
    })
  }

  preventE(event) {
    if (event.which === 101) {
      event.preventDefault();
    }
  }
  ngOnInit(): void {
    this.formLoading = true
    this.contractForm.get("Key").setValue(this.rFQId)
    this.requestService.getSingleRequestsApi(this.rFQId).subscribe((data: any) => {
      this.request = data
      this.getBankName(data.bank.split('#')[1])

      console.log(this.request, "this is the request")
      this.setExporter(data.seller)
      this.setImporter(data.buyer)
      this.contractForm.get("netweight").setValue(`${this.request.quantity}Mt`)
      this.contractForm.controls.preferredArrival.setValue(this.getReversedFormatDate())
      this.contractForm.get("contractDate").setValue(this.getReversedFormatDate())
      this.contractForm.get("preferredArrival").setValue(formatDate(data.date, 'yyyy-MM-dd', 'en'))
      this.contractForm.get("shippingTerms").setValue(data.shippingTerms)
      this.contractForm.get("quantity").setValue(data.quantity)
      this.productService.getSingleProductApi(this.request.product.split("#")[1]).subscribe((data: any) => {
        this.request.product = data
        this.contractForm.get("totalPrice").setValue(parseInt(this.request.quantity) * data.unitPrice)
        this.formLoading = false
        this.contractForm.get("HSCode").setValue(data.HSCode)
        this.contractForm.get("notes").setValue(data.notes.split('|')[0])
        this.contractForm.get("unitPrice").setValue(data.unitPrice)
        this.contractForm.get("importer").setValue(this.buyerName)
        this.contractForm.get("exporter").setValue(this.sellerName)
        this.authService.getSingleUserApi(this.getExporter(data.seller)).subscribe((data: any) => {
          this.contractForm.get("exporterAddress").setValue(data.body.address.street + ", " + data.body.address.city + ", " + data.body.address.country)
        })
      })
      this.authService.getSingleUserApi(this.getImporter(this.request.buyer)).subscribe((data: any) => {
        this.contractForm.get("importerAddress").setValue(data.body.address.street + ", " + data.body.address.city + ", " + data.body.address.country)
      })
    })

    this.contractService.getDealById(this.rFQId).subscribe((data: any) => {
      this.StartDeal = data
      console.log(data)
      // this.extraFeilds = JSON.parse(data.portOfDischarge.split('#')[1])
      const {
        vessel,
        voyage,
        grossWeight,
        netWeightContainer,
        netweight,
        totalNetWeight,
        totalGrossWeight,
        grossWeightContainer
      } = data
      this.extraFeilds =  {
      vessel,
      voyage,
      grossWeight,
      netweight,
      netWeightContainer,
      totalNetWeight,
      totalGrossWeight,
      grossWeightContainer
    }
      let currDeal = this.StartDeal.containerType.split(" ")
      if (this.userData.accountType === 'seller') {
        if (currDeal.includes('contractGenerated')) {
          for (var control in this.contractForm.controls) {
            this.contractForm.controls[control].disable();
          }
        }
      }
      if (this.userData.accountType === 'buyer') {
        if (currDeal.includes('contractGenerated')) {
          for (var control in this.contractForm.controls) {
            this.contractForm.controls[control].disable();
          }
        }
      }

      this.contractForm.get('containerQuantity').setValue(data.containerQuantity)
      this.contractForm.get('containerQuantity').setValue(data.containerQuantity)
      this.contractForm.get('dayCut').setValue(data.dayCut)
      this.contractForm.get('containerQuantity').disable()
      this.contractForm.get('containerType').disable()
      this.contractForm.get('dayCut').disable()

    })
  }
}
